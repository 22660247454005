import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { 
  MessageCircle, 
  ChevronLeft, 
  ChevronRight, 
  Maximize2, 
  X, 
  ArrowLeft,
  Info
} from 'lucide-react';
import { db } from '../../config/config';
import Footer from '../footer/Footer';
import RelatedProductsCarousel from './RelatedProductsCarousel';

// Utility functions with memoization
const utils = {
  findProduct: (allProducts, targetId) => 
    allProducts?.find(product => product.id === targetId) || null,

  extractProducts: (querySnapshot) => {
    const allProducts = [];
    querySnapshot?.forEach(doc => {
      const data = doc.data();
      if (data?.documents && Array.isArray(data.documents)) {
        allProducts.push(...data.documents);
      }
    });
    return allProducts;
  }
};

const ImageGallery = ({ images, title, selectedImageIndex, setSelectedImageIndex, isFullScreenMode, setIsFullScreenMode }) => {
  const handlePrevImage = useCallback((e) => {
    e?.stopPropagation();
    setSelectedImageIndex(prev => (prev - 1 + images.length) % images.length);
  }, [images.length, setSelectedImageIndex]);

  const handleNextImage = useCallback((e) => {
    e?.stopPropagation();
    setSelectedImageIndex(prev => (prev + 1) % images.length);
  }, [images.length, setSelectedImageIndex]);

  return (
    <>
      <div className="relative w-full pt-[75%] bg-gray-100 rounded-2xl overflow-hidden shadow-lg">
        <img 
          src={images[selectedImageIndex]}
          alt={title}
          className="absolute top-0 left-0 w-full h-full object-contain transition-opacity duration-300 ease-in-out"
          loading="lazy"
        />
        <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black/60 to-transparent">
          <div className="flex justify-between items-center">
            <span className="text-white text-sm font-medium">
              {`${selectedImageIndex + 1} / ${images.length}`}
            </span>
            <div className="flex gap-2">
              <button 
                onClick={() => setIsFullScreenMode(true)}
                className="bg-gradient-to-r from-[#DEC7FF] to-[#04000e] text-white p-2 rounded-full hover:opacity-90 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-[#DEC7FF] focus:ring-offset-2 focus:ring-offset-black/50"
                aria-label="View full size image"
              >
                <Maximize2 size={16} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 px-6">
        <div className="flex gap-3 overflow-x-auto pb-2 scrollbar-hide">
          {images.map((image, index) => (
            <button 
              key={index}
              className={`relative flex-shrink-0 w-20 h-20 rounded-lg overflow-hidden transition-all duration-300 ${
                index === selectedImageIndex 
                  ? 'ring-2 ring-[#DEC7FF] ring-offset-2' 
                  : 'opacity-70 hover:opacity-100 focus:ring-2 focus:ring-[#DEC7FF] focus:ring-offset-2'
              }`}
              onClick={() => setSelectedImageIndex(index)}
              aria-label={`View image ${index + 1}`}
            >
              <img 
                src={image}
                alt={`${title} - View ${index + 1}`}
                className="w-full h-full object-cover"
                loading="lazy"
              />
            </button>
          ))}
        </div>
      </div>

      {isFullScreenMode && (
        <div 
          className="fixed inset-0 bg-black/95 backdrop-blur-sm flex items-center justify-center z-50" 
          onClick={() => setIsFullScreenMode(false)}
        >
          <div className="relative w-full h-full flex items-center justify-center">
            <img 
              src={images[selectedImageIndex]}
              alt={`Full size view of ${title}`}
              className="max-w-[90%] max-h-[90vh] w-auto h-auto object-contain transition-transform duration-300"
            />
            <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-white/10 backdrop-blur-md text-white px-6 py-2 rounded-full">
              {`Image ${selectedImageIndex + 1} of ${images.length}`}
            </div>
            <button 
              className="absolute top-6 right-6 text-white bg-gradient-to-r from-[#DEC7FF] to-[#04000e] p-2 rounded-full hover:opacity-90 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-[#DEC7FF] focus:ring-offset-2 focus:ring-offset-black/50"
              onClick={(e) => { 
                e.stopPropagation(); 
                setIsFullScreenMode(false); 
              }}
              aria-label="Close full size image view"
            >
              <X size={20} />
            </button>
          </div>
          <div className="absolute inset-x-6 top-1/2 -translate-y-1/2 flex justify-between pointer-events-none">
            <button 
              onClick={handlePrevImage}
              className="pointer-events-auto bg-gradient-to-r from-[#DEC7FF] to-[#04000e] text-white p-3 rounded-full hover:opacity-90 transition-all duration-300 group focus:outline-none focus:ring-2 focus:ring-[#DEC7FF] focus:ring-offset-2 focus:ring-offset-black/50"
              aria-label="Previous image"
            >
              <ChevronLeft size={20} className="group-hover:-translate-x-0.5 transition-transform duration-300" />
            </button>
            <button 
              onClick={handleNextImage}
              className="pointer-events-auto bg-gradient-to-r from-[#DEC7FF] to-[#04000e] text-white p-3 rounded-full hover:opacity-90 transition-all duration-300 group focus:outline-none focus:ring-2 focus:ring-[#DEC7FF] focus:ring-offset-2 focus:ring-offset-black/50"
              aria-label="Next image"
            >
              <ChevronRight size={20} className="group-hover:translate-x-0.5 transition-transform duration-300" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'products'));
        const products = utils.extractProducts(querySnapshot);
        const foundProduct = utils.findProduct(products, id);
        
        if (foundProduct) {
          setProduct(foundProduct);
          setAllProducts(products);
        } else {
          setError('Product not found');
        }
      } catch (err) {
        console.error('Error fetching product:', err);
        setError('Error fetching product');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  const handleContactClick = useCallback(() => {
    navigate('/Checkout', { 
      state: { productTitle: product?.title } 
    });
  }, [navigate, product]);

  const allImages = useMemo(() => {
    if (!product) return [];
    return [product.mainImage, ...(product.descriptionImages || [])].filter(Boolean);
  }, [product]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="space-y-4 text-center">
          <div className="w-12 h-12 border-4 border-[#DEC7FF] border-t-transparent rounded-full animate-spin mx-auto" />
          <p className="text-gray-600 font-medium">Loading product...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="max-w-md p-8 bg-white rounded-2xl shadow-lg text-center space-y-6">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-100 text-red-500 mb-4">
            <Info size={32} />
          </div>
          <h2 className="text-2xl font-semibold text-gray-900">Oops!</h2>
          <p className="text-gray-600 leading-relaxed">{error}</p>
          <button
            onClick={() => navigate('/collection')}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-[#DEC7FF] to-[#04000e] rounded-lg hover:opacity-90 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-[#DEC7FF] focus:ring-offset-2"
          >
            <ArrowLeft size={16} className="mr-2" />
            Back to Collection
          </button>
        </div>
      </div>
    );
  }

  if (!product) return null;

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 font-sans">
      <main className="flex-grow pt-16 md:pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <nav className="flex items-center mb-10">
            <button 
              onClick={() => navigate('/collection')} 
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-[#DEC7FF] to-[#04000e] rounded-lg hover:opacity-90 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-[#DEC7FF] focus:ring-offset-2 shadow-sm"
            >
              <ArrowLeft size={16} className="mr-2" />
              Back to Collection
            </button>
          </nav>

          <div className="grid lg:grid-cols-2 gap-12">
            <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
              <ImageGallery 
                images={allImages}
                title={product.title}
                selectedImageIndex={selectedImageIndex}
                setSelectedImageIndex={setSelectedImageIndex}
                isFullScreenMode={isFullScreenMode}
                setIsFullScreenMode={setIsFullScreenMode}
              />
            </div>

            <div className="lg:sticky lg:top-24 h-fit">
              <div className="bg-white rounded-2xl shadow-lg p-8">
                <h1 className="text-4xl font-bold text-gray-900 capitalize leading-tight">
                  {product.title}
                </h1>

                <p className="mt-6 text-gray-600 text-lg leading-relaxed">
                  {product.description}
                </p>

                <div className="mt-10">
                  <button 
                    onClick={handleContactClick}
                    className="w-full inline-flex items-center justify-center bg-gradient-to-r from-[#DEC7FF] to-[#04000e] text-white px-6 py-3 rounded-xl font-semibold text-base hover:opacity-90 transition-all duration-300 shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-[#DEC7FF] focus:ring-offset-2"
                  >
                    <MessageCircle size={18} className="mr-2" />
                    Contact Now
                  </button>
                </div>
              </div>
            </div>
          </div>

          <section className="mt-16">
            <RelatedProductsCarousel 
              products={allProducts}
              currentProduct={product}
            />
          </section>
        </div>
      </main>
      
      <Footer />
    </div>
  );
};

export default ProductDetails;