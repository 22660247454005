import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, addDoc, getDocs, query, orderBy, limit, updateDoc, doc } from "firebase/firestore";
import { Send, ChevronLeft } from "lucide-react";
import { db } from "../../config/config";
import Footer from "../footer/Footer";

const API_KEY = "rPwMNVMhQ4DA8ni6IU2T5j8xGUs95l6k";
const BATCH_SIZE = 100;

const PincodeForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const productTitle = location.state?.productTitle || "";

  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    phoneNumber: "",
    emailAddress: "",
    streetAddress: "",
    city: "",
    stateProvinceRegion: "",
    postalZipCode: "",
    quantity: 1,
    agreeToTerms: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [branches, setBranches] = useState([]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    fetchBranches();
  }, []);

  const fetchBranches = async () => {
    try {
      const branchesSnapshot = await getDocs(collection(db, "branches"));
      const branchesData = branchesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBranches(branchesData);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  const getLatestBatch = async () => {
    try {
      const ordersRef = collection(db, "orders");
      const q = query(ordersRef, orderBy("createdAt", "desc"), limit(1));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        const newBatch = {
          batchNumber: 1,
          count: 0,
          documents: [],
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          totalBatches: 1,
        };
        const docRef = await addDoc(collection(db, "orders"), newBatch);
        return { id: docRef.id, ...newBatch };
      }

      const latestBatch = {
        id: querySnapshot.docs[0].id,
        ...querySnapshot.docs[0].data(),
      };
      return latestBatch;
    } catch (error) {
      console.error("Error getting latest batch:", error);
      throw error;
    }
  };

  const createNewBatch = async (batchNumber) => {
    const newBatch = {
      batchNumber,
      count: 0,
      documents: [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      totalBatches: batchNumber,
    };
    const docRef = await addDoc(collection(db, "orders"), newBatch);
    return { id: docRef.id, ...newBatch };
  };

  const geocode = async (pincode) => {
    try {
      const response = await fetch(
        `https://api.tomtom.com/search/2/geocode/${pincode}.json?key=${API_KEY}&country=IN`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        return data.results[0].position;
      }
      throw new Error("Location not found");
    } catch (error) {
      console.error("Geocoding error:", error);
      throw error;
    }
  };

  const calculateRoute = async (start, end) => {
    try {
      const response = await fetch(
        `https://api.tomtom.com/routing/1/calculateRoute/${start.lat},${start.lon}:${end.lat},${end.lon}/json?key=${API_KEY}`
      );
      const data = await response.json();
      return data.routes[0].summary.lengthInMeters;
    } catch (error) {
      console.error("Routing error:", error);
      throw error;
    }
  };

  const findNearestBranch = async (userCoords) => {
    let nearestBranch = null;
    let shortestDistance = Infinity;

    for (const branch of branches) {
      try {
        const branchCoords = await geocode(branch.pincode);
        const distance = await calculateRoute(userCoords, branchCoords);
        if (distance < shortestDistance) {
          shortestDistance = distance;
          nearestBranch = { ...branch, distance };
        }
      } catch (error) {
        console.error(`Error processing branch ${branch.id}:`, error);
      }
    }

    return nearestBranch;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: inputValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.agreeToTerms) {
      setError("Please agree to the terms and privacy policy.");
      return;
    }
    setLoading(true);
    setError("");
    setSuccess("");
    setProgress(0);

    try {
      setProgress(20);
      const userCoords = await geocode(formData.postalZipCode);
      setProgress(40);
      const nearestBranch = await findNearestBranch(userCoords);
      setProgress(60);

      if (nearestBranch) {
        const currentDate = new Date();
        const orderData = {
          ...formData,
          productTitle,
          branchId: nearestBranch.id,
          branchLocation: nearestBranch.location,
          roadDistance: (nearestBranch.distance / 1000).toFixed(2),
          createdAt: currentDate,
          agreedToTermsAt: currentDate,
          read: false,
          id: Math.random().toString(36).substr(2, 9),
        };

        let latestBatch = await getLatestBatch();

        if (latestBatch.count >= BATCH_SIZE) {
          latestBatch = await createNewBatch(latestBatch.batchNumber + 1);
        }

        const updatedDocuments = [...latestBatch.documents, orderData];
        const updatedBatch = {
          ...latestBatch,
          documents: updatedDocuments,
          count: updatedDocuments.length,
          updatedAt: new Date().toISOString(),
        };

        await updateDoc(doc(db, "orders", latestBatch.id), updatedBatch);

        setProgress(80);
        setSuccess(
          `Order placed for ${productTitle} in ${nearestBranch.location}`
        );
        setFormData({
          fullName: "",
          companyName: "",
          phoneNumber: "",
          emailAddress: "",
          streetAddress: "",
          city: "",
          stateProvinceRegion: "",
          postalZipCode: "",
          quantity: 1,
          agreeToTerms: false,
        });
        setProgress(100);
        setTimeout(() => {
          navigate("/Collection");
        }, 3000);
      } else {
        setError("Unable to find a nearest branch. Please try again.");
      }
    } catch (error) {
      console.error("Error adding order:", error);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="checkout-form min-h-screen bg-gray-50">
      <div className="flex flex-col min-h-screen overflow-hidden">
        <div className="flex-1 overflow-y-auto p-6">
          <button
            onClick={() => navigate("/Collection")}
            className="inline-flex items-center text-gray-600 mb-6 bg-transparent border-none cursor-pointer text-sm hover:text-gray-800 transition-colors duration-300"
          >
            <ChevronLeft size={20} />
            <span className="ml-2">Back to Products</span>
          </button>

          <div className="bg-white rounded-2xl p-8 shadow-lg max-w-2xl mx-auto">
            <h2 className="text-3xl font-bold mb-6 text-gray-900">Checkout</h2>
            {productTitle && (
              <p className="text-base text-gray-600 mb-8">Product: {productTitle}</p>
            )}
            <form onSubmit={handleSubmit}>
              {[
                { name: "fullName", label: "Full Name", type: "text", required: true },
                { name: "companyName", label: "Company Name (optional)", type: "text" },
                { name: "phoneNumber", label: "Phone Number", type: "tel", required: true },
                { name: "emailAddress", label: "Email Address (optional)", type: "email" },
                { name: "streetAddress", label: "Street Address", type: "text", required: true },
                { name: "city", label: "City", type: "text", required: true },
                { name: "stateProvinceRegion", label: "State/Province/Region", type: "text", required: true },
                { name: "postalZipCode", label: "Postal/ZIP Code", type: "text", required: true },
                { name: "quantity", label: "Quantity", type: "number", required: true, min: 1 },
              ].map((field) => (
                <div key={field.name} className="mb-6">
                  <label
                    htmlFor={field.name}
                    className="block mb-2 text-sm font-medium text-gray-700"
                  >
                    {field.label}
                  </label>
                  <input
                    id={field.name}
                    name={field.name}
                    type={field.type}
                    value={formData[field.name]}
                    onChange={handleInputChange}
                    required={field.required}
                    min={field.min}
                    className="w-full px-4 py-2 border-b border-gray-300 focus:border-[#DEC7FF] outline-none transition-colors duration-300 bg-transparent"
                  />
                </div>
              ))}
              <div className="mb-8">
                <label className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    name="agreeToTerms"
                    checked={formData.agreeToTerms}
                    onChange={handleInputChange}
                    className="form-checkbox h-5 w-5 text-[#DEC7FF] rounded-md focus:ring-[#DEC7FF] border-gray-300"
                  />
                  <span className="ml-3 text-sm text-gray-600">
                    I agree to the{" "}
                    <a
                      href="/terms-of-service"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#DEC7FF] hover:underline"
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#DEC7FF] hover:underline"
                    >
                      Privacy Policy
                    </a>.
                  </span>
                </label>
              </div>
              <button
                type="submit"
                disabled={loading || !formData.agreeToTerms}
                className={`w-full text-white py-3 rounded-xl font-semibold text-base transition-all duration-300 ${
                  formData.agreeToTerms
                    ? "bg-gradient-to-r from-[#DEC7FF] to-[#04000e] hover:opacity-90"
                    : "bg-gray-300 cursor-not-allowed"
                }`}
              >
                {loading ? (
                  "Processing..."
                ) : (
                  <>
                    <Send size={20} className="inline mr-2" /> Place Order
                  </>
                )}
              </button>
            </form>
            {error && <div className="text-red-500 mt-4 text-center">{error}</div>}
            {success && (
              <div className="text-green-500 mt-4 text-center">{success}</div>
            )}
            {loading && (
              <div className="mt-4">
                <div className="w-full bg-gray-200 rounded-full h-1">
                  <div
                    className="bg-gradient-to-r from-[#DEC7FF] to-[#04000e] h-full rounded-full transition-all duration-300"
                    style={{ width: `${progress}%` }}
                  />
                </div>
                <p className="text-center mt-2 text-sm text-gray-600">
                  {progress}% Complete
                </p>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </section>
  );
};

export default PincodeForm;